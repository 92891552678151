import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Accordion, Icon } from 'semantic-ui-react';
import formatNumber from 'helpers/formatNumber';

const SimpleAccordion = ({ data, activeIndex }) => {
  const [isActive, setActive] = useState(-1);

  const handleChange = (e, { index }) => {
    setActive(isActive === index ? -1 : index);
  };
  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === isActive}
        index={activeIndex}
        onClick={handleChange}
        className="accordion__container"
      >
        <span className="accordion__label">
          <Icon name="dropdown" />
          {data?.label}:
        </span>
        <span className="meta">{formatNumber(data?.value)} RWF</span>
      </Accordion.Title>
      {activeIndex === isActive &&
        data?.subs?.map(item => (
          <Accordion.Content
            active={activeIndex === isActive}
            className="accordion__item"
          >
            <span>{item?.label}:</span>
            <span>{formatNumber(item?.value)} RWF</span>
          </Accordion.Content>
        ))}
    </Accordion>
  );
};

SimpleAccordion.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default SimpleAccordion;

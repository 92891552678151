import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import moment from 'moment';
import saveBlueLogo from 'assets/images/save_blue.png';
import paidImage from 'assets/images/paid.png';
import failedImage from 'assets/images/failed-transaction.svg';
import './index.scss';
import currencyFormat from 'utils/currencyFormat';

const Receipt = ({
  originator,
  recipient,
  transactionId,
  date,
  channel,
  amount,
  comment,
  status,
  transactionPurpose,
  type,
}) => {
  const { name, id } = recipient;
  const formatedDate = moment(date).format('DD-MM-YYYY');
  return (
    <div className="receipt" id="receipt">
      <div className="receipt__header">
        <div className="receipt__header--title">
          <div>
            <img
              src={saveBlueLogo}
              alt="save"
              className="receipt__header--logo"
            />
          </div>
        </div>
      </div>

      <div className="receipt__details">
        <div className="row">
          <div className="receipt__details--item">
            <div className="receipt__details--item--title">
              Originator / Creditor
            </div>
            <div className="receipt__details--item--value">
              {originator}
            </div>
          </div>

          <div className="receipt__details--item row">
            <div className="receipt__details--item--title">
              TRANSACTION ID
            </div>
            <div className="receipt__details--item--value">
              {transactionId}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="receipt__details--item">
            <div className="receipt__details--item--title">
              Recipient
            </div>
            <div className="receipt__details--item--value">
              {name && id ? (
                <>
                  <div className="receipt__details--item--value--desc">
                    <strong>
                      {type === 'debit' &&
                      transactionPurpose !== 'group_cash'
                        ? 'Tel:'
                        : 'Id:'}
                    </strong>{' '}
                    {id}
                  </div>
                  <div className="receipt__details--item--value--desc">
                    <strong>Name:</strong> {name}
                  </div>
                </>
              ) : (
                recipient
              )}
            </div>
          </div>

          <div className="receipt__details--item row">
            <div className="receipt__details--item--title">DATE</div>
            <div className="receipt__details--item--value">
              {formatedDate}
            </div>
          </div>
        </div>

        <div className="receipt__details--description">
          <div className="receipt__details--description--title">
            DESCRIPTION
          </div>
          <div className="receipt__details--description--content">
            {comment || transactionPurpose}
          </div>
        </div>

        <div className="receipt__channel">
          <div className="row">
            <div className="receipt__details--item">
              <div className="receipt__details--item--title">
                CHANNEL
              </div>
              <div className="receipt__details--item--value">
                {startCase(channel)}
              </div>
            </div>

            <div className="receipt__channel--paid">
              <div className="receipt__channel--paid--amount">
                TOTAL {currencyFormat(amount)}
              </div>

              <img
                src={
                  ['rejected', 'declined', 'failed'].includes(status)
                    ? failedImage
                    : paidImage
                }
                alt="paid"
              />
            </div>
          </div>
        </div>

        <div className="receipt__note">
          <span>
            <b>SAVE</b> is a product of Exuus <br /> Terms and
            conditions apply
          </span>
        </div>

        <div className="receipt__footer">
          <div className="receipt__footer--item">
            support@getsave.io
          </div>

          <div className="receipt__footer--item">7777</div>

          <div className="receipt__footer--item">TIN:103354109</div>

          <div className="receipt__footer--item">getsave.io</div>
        </div>
      </div>
    </div>
  );
};

Receipt.propTypes = {
  originator: PropTypes.string,
  recipient: PropTypes.any,
  transactionId: PropTypes.number,
  date: PropTypes.string,
  channel: PropTypes.string,
  transactionPurpose: PropTypes.string,
  comment: PropTypes.string,
  amount: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
};

Receipt.defaultProps = {
  type: '',
  status: '',
  amount: 0,
  originator: '',
  recipient: '',
  transactionId: 0,
  date: '',
  channel: '',
  transactionPurpose: '',
  comment: '',
};

export default Receipt;

import SingleProfile from 'containers/Profiles/SingleProfile';

export default {
  exact: true,
  name: 'Profile',
  protected: true,
  path: '/profiles/:profileId/:tab?/:requestId?',
  component: SingleProfile,
  permissions: ['all'],
};

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Sidebar,
  Icon,
  Form,
  Input,
  Select,
  Segment,
} from 'semantic-ui-react';
import {
  toAreaDropDownOptions,
  toDropDownOptions,
} from 'utils/dropdownOptions';
import GroupSearch from 'components/Organizations/SingleOrganization/Groups/GroupSearch';
import SearchMember from 'components/Organizations/SingleOrganization/Groups/Member/SearchMember';
import SelectMember from 'components/Organizations/SingleOrganization/Groups/Member/SelectMember';
import telcoOptions from 'constants/options/telcoOptions';
import platformOptions from 'constants/platformOptions';
import statusOptions from 'constants/options/statusOptions';
import walletOptions from 'constants/options/walletOptions';
import groupTypeOptions from 'constants/options/groupTypeOptions';
import activities from 'constants/activities';
import {
  frequencyTypes,
  purposeTypes,
  transactionTypes,
  walletTypes,
} from 'constants/billingTypes';
import DropdownCycleFilter from 'components/common/DropdownCycleFilter';
import TYPE_NAMES from 'constants/typeNames';
import featureTypeOptions from 'constants/featureTypeOptions';
import featureStatusOptions from 'constants/featureStatusOptions';
import featureFrequencyOptions from 'constants/featureFrequencyOptions';

const SidebarFilter = ({
  filterOn,
  setFilterOn,
  form,
  setForm,
  filterTransactions,
  handleChange,
  types,
  category,
  typeName,
  searchName,
  activity,
  resourceId,
  group,
}) => {
  const [clear, setClear] = useState(false);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const handleClear = () => {
    if (form.filter) setForm({ search: null, filter: null });
    if (form.resource) setForm({ search: null, resource: null });
    if (form.platform) setForm({ ...form, platform: null });
    if (form.purpose) setForm({ purpose: null });
    if (form.search) setForm({ search: null });
    if (form.provider_name) setForm({ search: null });
    if (form.category) setForm({ search: null });
    if (form.type) setForm({ search: null });
    setClear(true);
    push(pathname);
  };

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  if (clear) {
    return <></>;
  }

  const checkActivity = () => {
    switch (activity) {
      case activities.GROUP:
        if (category === 'transactions') return form.type;
        return form.resource;
      case activities.MEMBER:
        return form.purpose;
      default:
        return form.filter;
    }
  };

  const sidebarClass =
    activity === activities.GROUP_TRANSACTIONS
      ? 'sidefilter'
      : activity === activities.SETTING_FEATURES
      ? 'resized'
      : 'modified';

  const nonDefaultActivities = [
    activities.ALL_SAVING_GROUPS,
    activities.GROUP_TRANSACTIONS,
    activities.SETTING_FEATURES,
    activities.BILLINGS,
  ];

  return (
    <Sidebar
      className={sidebarClass}
      as={Segment}
      direction="right"
      animation="overlay"
      visible={filterOn}
      width="wide"
    >
      <div className="padding">
        <div className="header">
          <div
            tabIndex="-1"
            role="button"
            onKeyDown={() => {}}
            className="back"
            onClick={() => {
              setFilterOn(false);
            }}
          >
            <Icon name="arrow left" fitted size="large" />
          </div>
          <span className="title titleText">Filter</span>
          <Button
            size="small"
            onClick={handleClear}
            content="Clear"
          />
        </div>
      </div>
      <div className="padding">
        <Form onSubmit={filterTransactions}>
          {!activity && (
            <Form.Field
              id="input-name"
              name="search"
              control={Input}
              label={searchName}
              placeholder={`${searchName}...`}
              onChange={handleChange}
            />
          )}

          {activity === activities.AGENT && (
            <GroupSearch
              label="Group Name"
              name="search"
              handleChange={handleChange}
              organizationId={resourceId}
            />
          )}

          {activity === activities.GROUP &&
          category === 'transactions' ? (
            <>
              <DropdownCycleFilter
                handleChange={(e, { cycle_id }) => {
                  handleChange(e, {
                    name: 'cycle_id',
                    value: cycle_id,
                  });
                }}
                label="Cycle"
                name="cycle_id"
                group={group}
              />
              <SelectMember
                label="Member Name"
                name="user_id"
                handleChange={handleChange}
                groupId={resourceId}
                defaultValue={form.user_id}
              />
            </>
          ) : (
            activity === activities.GROUP && (
              <SearchMember
                label="Member Name"
                name="search"
                handleChange={handleChange}
                groupId={resourceId}
                defaultValue={form.search}
              />
            )
          )}

          {activity === activities.ALL_SAVING_GROUPS && (
            <>
              <Form.Field
                name="type"
                control={Select}
                search
                fluid
                clearable
                placeholder="type"
                label="Group Type"
                value={form.type}
                onChange={handleChange}
                options={toDropDownOptions(groupTypeOptions)}
              />
              <Form.Field
                name="status"
                control={Select}
                search
                fluid
                clearable
                placeholder="Status"
                label="Group Status"
                value={form.status}
                onChange={handleChange}
                options={toDropDownOptions(statusOptions)}
              />
              <Form.Field
                name="access_bank"
                control={Select}
                search
                fluid
                clearable
                placeholder="Wallet"
                label="Group Wallet"
                value={form.access_bank}
                onChange={handleChange}
                options={toAreaDropDownOptions(walletOptions)}
              />
            </>
          )}

          {activity === activities.BILLINGS && (
            <>
              <Form.Field
                name="charge_from_wallet"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.charge_from_wallet}
                options={toDropDownOptions(walletTypes)}
                clearable
                label="Wallet Type"
                placeholder="Wallet Type"
                onChange={handleChange}
              />
              <Form.Field
                name="transaction_type"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.transaction_type}
                options={toDropDownOptions(transactionTypes)}
                clearable
                label="Transaction Type"
                placeholder="Transaction Type"
                onChange={handleChange}
              />
              <Form.Field
                name="transaction_purposes"
                control={Select}
                fluid
                selection
                multiple
                defaultValue={form.transaction_purposes}
                options={toDropDownOptions(purposeTypes)}
                clearable
                label="Transaction categories"
                placeholder="Transaction categories"
                onChange={handleChange}
              />
              <Form.Field
                name="charge_frequency"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.charge_frequency}
                options={toDropDownOptions(frequencyTypes)}
                clearable
                label="Frequency"
                placeholder="Frequency"
                onChange={handleChange}
              />
            </>
          )}

          {activity === activities.GROUP_TRANSACTIONS && (
            <>
              <Form.Field
                name="purpose"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.purpose}
                options={types}
                clearable
                label="Transaction Type"
                placeholder="Type"
                onChange={handleChange}
              />
              <Form.Field
                name="provider_name"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.provider_name}
                options={toDropDownOptions(telcoOptions, true)}
                clearable
                label="Transaction Provider"
                placeholder="Provider Name"
                onChange={handleChange}
              />
              <Form.Field
                name="status"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.status}
                options={toDropDownOptions([
                  'success',
                  'pending',
                  'failed',
                ])}
                clearable
                label="Transaction Status"
                placeholder="Status"
                onChange={handleChange}
              />
              <Form.Field
                name="type"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.type}
                options={toDropDownOptions(['credit', 'debit'])}
                clearable
                label={typeName}
                placeholder="Purpose"
                onChange={handleChange}
              />
              <Form.Field
                name="category"
                control={Select}
                fluid
                search
                selection
                defaultValue={form.category}
                options={toDropDownOptions(['internal', 'external'])}
                clearable
                label="Transaction Category"
                placeholder="Category"
                onChange={handleChange}
              />
              <Form.Input
                fluid
                type="date"
                search
                clearable
                selection
                placeholder="Created at"
                label="Transaction Date"
                name="created_at"
                onChange={handleChange}
                defaultValue={form?.dateFilter}
              />
            </>
          )}
          {activity === activities.SETTING_FEATURES && (
            <>
              <Form.Select
                name="platform"
                placeholder="Platform Name"
                label="Platform"
                clearable
                search
                fluid
                value={form.platform}
                options={toDropDownOptions(platformOptions)}
                onChange={handleChange}
              />
              <Form.Select
                name="active_frequency"
                placeholder="Frequency"
                label="Frequency"
                clearable
                search
                fluid
                value={form.active_frequency}
                options={toDropDownOptions(featureFrequencyOptions)}
                onChange={handleChange}
              />
              <Form.Select
                name="feature_name"
                placeholder="Type"
                label="Accessibility level"
                clearable
                search
                fluid
                value={form.feature_name}
                options={toDropDownOptions(featureTypeOptions)}
                onChange={handleChange}
              />
              <Form.Select
                name="status"
                placeholder="Status"
                label="Status"
                clearable
                search
                fluid
                value={form.status}
                options={toDropDownOptions(featureStatusOptions)}
                onChange={handleChange}
              />
            </>
          )}
          {activity === activities.MEMBER &&
            typeName === TYPE_NAMES.TRANSACTION_PURPOSE && (
              <DropdownCycleFilter
                handleChange={(e, { cycle_id }) => {
                  handleChange(e, {
                    name: 'cycle_id',
                    value: cycle_id,
                  });
                }}
                label="Cycle"
                name="cycle_id"
                group={group}
              />
            )}
          {!nonDefaultActivities.includes(activity) && (
            <Form.Field
              name={
                activity === activities.GROUP
                  ? category === 'transactions'
                    ? 'purpose'
                    : 'resource'
                  : activity === activities.MEMBER
                  ? 'purpose'
                  : 'filter'
              }
              control={Select}
              fluid
              search
              selection
              defaultValue={checkActivity()}
              options={types}
              label={typeName}
              placeholder="-All-"
              onChange={(e, { name, value }) => {
                if (name === 'purpose') {
                  return handleChange(e, { name, value: [value] });
                }

                handleChange(e, { name, value });
              }}
            />
          )}
        </Form>
      </div>
    </Sidebar>
  );
};

SidebarFilter.defaultProps = {
  searchName: 'Name',
  typeName: TYPE_NAMES.TYPE,
  activity: null,
  resourceId: null,
  group: {},
};

SidebarFilter.propTypes = {
  filterOn: PropTypes.bool.isRequired,
  setFilterOn: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  group: PropTypes.instanceOf(Object),
  setForm: PropTypes.func.isRequired,
  filterTransactions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  types: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.string.isRequired,
  searchName: PropTypes.string,
  typeName: PropTypes.string,
  activity: PropTypes.string,
  resourceId: PropTypes.string,
};

export default SidebarFilter;

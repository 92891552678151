/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';
import { Form, Select, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import SimpleModal from 'components/common/modals/SimpleModal';
import PINInput from 'components/common/PINInput';
import telcoOptions from 'constants/options/telcoOptions';
import { toDropDownOptions } from 'utils/dropdownOptions';
import approveLoanRequests from 'redux/actions/loanRequests/approveLoanRequests';
import getCurrentOrg from 'helpers/getCurrentOrg';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import allFieldsRequired from 'helpers/validators/validateFields.js';

const LoanApprovalModal = ({ open, setOpen, request }) => {
  const { push } = useHistory();
  const { loading } = useSelector(
    ({ loanRequests: { approveLoanRequest } }) => approveLoanRequest,
  );

  const profileInfo =
    request?.group?.loan_profile_monthly?.[0]?.loan_profile;

  const currentUserOrganization = getCurrentOrg();
  const [form, setForm] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isRequired = allFieldsRequired(
      ['channel', 'pinNumber'],
      form,
    );

    if (isRequired) {
      setShowErrors(false);

      return approveLoanRequests(
        currentUserOrganization.id,
        request?.id,
        {
          channel: form.channel,
          password: form.pinNumber,
        },
      )(dispatch, () => {
        setOpen(null);
        if (profileInfo?.id) {
          push(`/profiles/${profileInfo.id}/requests/${request?.id}`);
        }
      });
    }
    return setShowErrors(true);
  };

  const handleFormChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <SimpleModal
      open={open}
      title="Confirm Loan Approval"
      onClose={() => setOpen(null)}
    >
      <div className="padding confirm-loan-approval">
        <Form onSubmit={handleSubmit} loading={loading}>
          <Form.Dropdown
            name="channel"
            control={Select}
            fluid
            options={toDropDownOptions(telcoOptions, true)}
            className="confirm-loan-approval__select-input"
            label="Select channel"
            placeholder="Select"
            onChange={handleFormChange}
            error={isFieldRequired(
              showErrors,
              !form?.channel,
              'Channel',
            )}
          />

          <span>PIN</span>
          <PINInput
            fluid
            size="tiny"
            label="PIN"
            name="pinNumber"
            className="confirm-loan-approval__pin-input"
            onInputChange={handleFormChange}
            error={{
              message: isFieldRequired(
                showErrors,
                !form?.pinNumber,
                'PIN Number',
              ),
            }}
          />

          <Button
            disabled={loading}
            loading={loading}
            primary
            fluid
            size="medium"
            type="submit"
            content="Confirm"
          />
        </Form>
      </div>
    </SimpleModal>
  );
};

LoanApprovalModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
};

export default LoanApprovalModal;

/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Message, Breadcrumb } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import getProfileGroups from 'redux/actions/organizations/profiles/profileGroups';
import Loader from 'components/common/PageLoader';
import getCurrentOrg from 'helpers/getCurrentOrg';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import LoanRequestTable from 'containers/MicroLoanRequests/LoanRequestTable';
import Empty from 'components/common/Empty';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import './index.scss';
import getLoanRequests from 'redux/actions/loanRequests/getLoanRequests';

const ProfileRequestList = ({ backToProfileOverview }) => {
  const params = useParams();
  const { search, pathname } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryParams = Object.fromEntries(urlParams);
  const {
    organizationId,
    profileId: theProfileId,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const currentUserOrganization = getCurrentOrg();

  const { items, meta, loading, error } = useSelector(
    ({ loanRequests: { pendingLoanRequests } }) =>
      pendingLoanRequests,
  );

  const dispatch = useDispatch();

  const {
    data: { profile },
  } = useSelector(
    ({ profiles: { profileOverview } }) => profileOverview,
  );

  useEffect(() => {
    getLoanRequests({
      organization_profile_id: profileId,
      ...queryParams,
    })(dispatch);
  }, [profileId]);

  const getRequests = (
    organizationId,
    profileId,
    queries = queryString.parse(search),
  ) => {
    return getProfileGroups(
      organizationId,
      profileId,
      queries,
    )(dispatch);
  };

  useEffect(() => {
    getProfileOverview(
      organizationId || currentUserOrganization?.id,
      profileId,
    )(dispatch);
  }, [organizationId, currentUserOrganization, profileId]);

  const onSearch = queries => {
    getRequests(
      organizationId || currentUserOrganization?.id,
      profileId,
      queries,
    );
  };

  const onPaginate = queries => {
    getRequests(
      organizationId || currentUserOrganization?.id,
      profileId,
      queries,
    );
  };

  return (
    <div className="profile-requests__lists">
      <Toolbar
        header={{
          title: !pathname.includes('/organizations/') && 'Requests',
          navigation: false,
          leftComponent: pathname.includes('/organizations/') &&
            profile && (
              <Breadcrumb size="massive" className="customized-title">
                <Breadcrumb.Section active>
                  Profile
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section
                  className="cursor"
                  link={false}
                  onClick={() => {
                    backToProfileOverview(profile?.id);
                  }}
                  active
                >
                  {profile?.name}
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>Groups</Breadcrumb.Section>
              </Breadcrumb>
            ),
          rightComponent: (
            <ToolbarFooter
              onSearch={onSearch}
              onPaginate={onPaginate}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="groups"
            />
          ),
        }}
      />

      <div className="list-profiles__items scroll-wrapper">
        {loading ? (
          <Loader />
        ) : isEmpty(items) ? (
          <Empty message="No pending request" />
        ) : error ? (
          <Message negative>{error?.message || error}</Message>
        ) : (
          <LoanRequestTable items={items} />
        )}
      </div>
    </div>
  );
};

ProfileRequestList.propTypes = {
  backToProfileOverview: PropTypes.func.isRequired,
};

export default ProfileRequestList;

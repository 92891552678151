import notification from 'helpers/notificationHelper';
import apiAction from 'helpers/apiAction';
import {
  CREATE_PROFILE_FAILED,
  CREATE_PROFILE_START,
  CREATE_PROFILE_SUCCESS,
} from 'constants/action-types/profiles/createProfiles';

export default ({ id, data }) => (dispatch, onSuccess) =>
  dispatch(
    apiAction({
      url: `/organizations/${id}/profiles`,
      method: 'POST',
      data,
      API_REQUEST_START: CREATE_PROFILE_START,
      API_REQUEST_SUCCESS: CREATE_PROFILE_SUCCESS,
      API_REQUEST_FAILURE: CREATE_PROFILE_FAILED,
      callbackOnSuccess: ({ message }) => {
        onSuccess();
        notification({ message });
      },
      callbackOnFailure: ({ message }) =>
        notification({ message, status: 'error' }),
      onEnd: () => false,
    }),
  );

/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Label, Icon } from 'semantic-ui-react';
import { capitalizeEachWords } from 'utils/capitalize';
import formatPhone from 'helpers/formatPhone';
import currencyFormat from 'utils/currencyFormat';
import formatDate from 'utils/formatDate';

const getStatusColor = status => {
  switch (status) {
    case 'pending':
      return 'yellow';

    case 'approved':
      return 'green';

    case 'accepted':
      return 'green';

    case 'submitted':
      return 'green';

    case 'amended':
      return 'blue';

    default:
      break;
  }
};

const RequestPopupContent = ({ request }) => {
  return (
    <div className="loan-request-info-popup">
      <div className="loan-request-info-popup__header">
        <b className="">
          {capitalizeEachWords(request?.type?.replace(/_/g, ' '))}
        </b>

        <span
          className={`loan-request-info-popup__${
            request?.status === 'submitted'
              ? 'pending'
              : request?.status
          }`}
        >
          {request?.status === 'submitted'
            ? 'Pending'
            : capitalizeEachWords(request?.status)}
        </span>
      </div>
      <div className="loan-request-info-popup__body">
        <div className="loan-request-info-popup__body__loan-info">
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Loan amount:</span>
            <span>{currencyFormat(request?.amount)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Disbursed amount:</span>
            <span>
              {currencyFormat(request?.loan_disbursed_amount)}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Disbursed date:</span>
            <span>
              {formatDate(request?.loan_disbursed_date, 'DD-MM-YYYY')}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Repayment Days:</span>
            <span>{request?.repayment_days}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Repayment instalments:</span>
            <span>{request?.repayment_installments}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Payment/Installment:</span>
            <span>
              {currencyFormat(request?.repayment_installment_amount)}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Interest:</span>
            <span>{currencyFormat(request?.loans_interest)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Due Date:</span>
            <span>
              {formatDate(request?.loans_due_date, 'DD-MM-YYYY')}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Payed amount:</span>
            <span>{currencyFormat(request?.loan_paid_amount)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Overdue Loan:</span>
            <span>
              {currencyFormat(request?.overdue_loans_interests)}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Outstanding Balance:</span>
            <span>{currencyFormat(request?.loans_balance)}</span>
          </div>
        </div>

        <div className="loan-request-info-popup__body__committe-info">
          <div className="loan-request-info-popup__body__committe-info__title">
            <span>Committee approvals</span>
          </div>

          {request?.approvals?.map(commettee => (
            <div
              key={`${commettee?.id}`}
              className="loan-request-info-popup__body__committe-info__item"
            >
              <div className="loan-request-info-popup__body__committe-info__item__member">
                <span>{`${commettee?.user?.first_name} ${commettee?.user?.last_name}`}</span>
                <span className="loan-request-info-popup__body__committe-info__item__phone">
                  <Icon name="phone" />{' '}
                  <span>
                    {formatPhone(commettee?.user?.phone_number)}
                  </span>
                </span>
              </div>

              <Label color={getStatusColor(commettee.status)}>
                {commettee.status === 'accepted'
                  ? 'Approved'
                  : capitalizeEachWords(commettee.status)}
              </Label>
            </div>
          ))}
        </div>
        <div className="loan-request-info-popup__body__committe-info">
          <div className="loan-request-info-popup__body__committe-info__title">
            <span>Agent advisory comment</span>
          </div>
          <div className="loan-request-info-popup__body__committe-info__item">
            <div className="loan-request-info-popup__body__committe-info__item__member">
              <span>{`${request?.agent?.first_name} ${request?.agent?.last_name}`}</span>
              <span className="loan-request-info-popup__body__committe-info__item__phone">
                <Icon name="phone" />{' '}
                <span>
                  {formatPhone(request?.agent?.phone_number)}
                </span>
              </span>
            </div>

            <span
              className={`loan-request-info-popup__${request?.agent_approval_status}`}
            >
              {capitalizeEachWords(request?.agent_approval_status)}
            </span>
          </div>
          {request?.comment && (
            <div className="loan-request-info-popup__body__committe-info__item__member">
              <span>Comment</span>
              <span className="loan-request-info-popup__body__committe-info__item__phone">
                {request?.comment}
              </span>
            </div>
          )}
        </div>
        <div className="loan-request-info-popup__body__orgs-info">
          <div className="loan-request-info-popup__body__orgs-info__title">
            <span>Organisation admin approval</span>
          </div>
          <div className="loan-request-info-popup__body__orgs-info__item">
            <div className="loan-request-info-popup__body__orgs-info__item__member">
              <span>{request?.organization?.name}</span>
            </div>

            <Label
              color={getStatusColor(
                request?.status === 'submitted'
                  ? 'pending'
                  : request.status,
              )}
            >
              {request?.status === 'submitted'
                ? 'Pending'
                : capitalizeEachWords(request?.status)}
            </Label>
          </div>
          {request?.status === 'amended' && (
            <div className="loan-request-info-popup__body__orgs-info__item__">
              <span className="loan-request-info-popup__body__orgs-info__title">
                Reason for amendment
              </span>
              <span className="loan-request-info-popup__body__orgs-info__item__comment">
                {request?.amendedReason}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

RequestPopupContent.propTypes = {
  request: PropTypes.object.isRequired,
};

export default RequestPopupContent;

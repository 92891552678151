/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import EditProfile from 'containers/Profiles/SingleProfile/Settings/EditProfile';
import OrganizationSettings from 'containers/Organizations/SingleOrganization/Settings';
import OrganizationProjects from 'containers/Organizations/SingleOrganization/Projects';
import OrganizationGroups from 'containers/Organizations/SingleOrganization/Groups';
import OrganizationOverview from 'components/Organizations/SingleOrganization/Overview';
import { isSuperAdmin } from 'helpers/checkRoles';
import OrganizationAgents from 'containers/Organizations/SingleOrganization/Agents';
import { isBusinessOrganization } from 'helpers/organizationType';
import AllProfiles from 'containers/Profiles/AllProfiles';
import ProfileGroups from 'containers/Profiles/SingleProfile/Groups';
import getCurrentOrg from 'helpers/getCurrentOrg';
// import { canAccess } from 'helpers/permissions';
import Overview from 'containers/Profiles/SingleProfile/Overview';
import FspOverviews from 'components/Organizations/SingleOrganization/Overview/FspOverviews';

const profileSections = {
  overview: 'overview',
  groups: 'groups',
  requests: 'requests',
  settings: 'settings',
};

const ProfileAgregator = ({
  location,
  history,
  match,
  openSingleProfile,
}) => {
  return (
    <>
      {match.params.option1 === profileSections.overview && (
        <Overview
          location={location}
          history={history}
          match={match}
        />
      )}
      {match.params.option1 === profileSections.groups && (
        <ProfileGroups
          location={location}
          history={history}
          match={match}
          backToProfileOverview={openSingleProfile}
        />
      )}
      {match.params.option1 === profileSections.requests && (
        <ProfileGroups
          location={location}
          history={history}
          match={match}
          backToProfileOverview={openSingleProfile}
        />
      )}
      {match.params.option1 === profileSections.settings && (
        <EditProfile
          location={location}
          history={history}
          match={match}
          backToProfileOverview={openSingleProfile}
        />
      )}
    </>
  );
};

const OrganizationTab = ({ location, history, match }) => {
  const {
    details: { roles },
  } = useSelector(({ user: { currentUser } }) => currentUser);
  const { data } = useSelector(
    ({ organizations: { getSingleOrganization } }) =>
      getSingleOrganization,
  );
  const currentUserOrganization = getCurrentOrg();
  const {
    params: { organizationId, tab },
    params,
  } = match;

  const { pathname } = useLocation();

  const isBusiness = isBusinessOrganization(data?.organization);
  const { push } = useHistory();

  const panes = props => [
    {
      menuItem: 'Overview',
      render: () => {
        if (isBusiness !== undefined)
          return isBusiness ? (
            <FspOverviews {...props} />
          ) : (
            <OrganizationOverview {...props} />
          );
      },
    },
    {
      menuItem: isBusiness ? 'Profiles' : 'Projects',
      render: () =>
        isBusiness ? (
          params.tabOption ? (
            <ProfileAgregator {...props} />
          ) : (
            <AllProfiles {...props} />
          )
        ) : (
          <OrganizationProjects {...props} />
        ),
    },
    {
      menuItem: 'Agents',
      render: () => <OrganizationAgents {...props} />,
    },
    {
      menuItem: 'Saving Groups',
      render: () => <OrganizationGroups {...props} />,
    },
    {
      menuItem: 'Settings',
      render: () => <OrganizationSettings {...props} settings />,
    },
  ];

  useEffect(() => {
    if (roles) {
      if (!isSuperAdmin(roles)) {
        push('/');
      }
    }
  }, []);

  const tabs = [
    'overview',
    isBusinessOrganization(data?.organization)
      ? 'profiles'
      : 'projects',
    'agents',
    'groups',
    'settings',
  ];

  const getPath = tabName =>
    `/organizations/${organizationId}/${tabName}`;

  const handleTabChange = (e, { activeIndex }) => {
    const tab = tabs[activeIndex];
    push(getPath(tab));
  };

  const openSingleProfile = profileId => {
    push(
      pathname === '/profiles'
        ? `/profiles/${profileId}/overview`
        : `/organizations/${params?.organizationId ||
            currentUserOrganization?.id}/profiles/${profileId}/overview`,
    );
  };

  const activeIndex = tabs.findIndex(item => item === tab) || 0;

  return (
    <Tab
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes({
        location,
        history,
        match,
        openSingleProfile,
      })}
      onTabChange={handleTabChange}
    />
  );
};

OrganizationTab.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

ProfileAgregator.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
  openSingleProfile: PropTypes.func.isRequired,
};

export default OrganizationTab;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import ProfileGroups from 'containers/Profiles/SingleProfile/Groups';
import getCurrentOrg from 'helpers/getCurrentOrg';
import { canAccess } from 'helpers/permissions';
import Overview from 'containers/Profiles/SingleProfile/Overview';
import EditProfile from './Settings/EditProfile';
import Requests from './Requests';
import SingleLoanRequest from './Requests/SingleRequest';

const ProfileTab = ({ location, history, match }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const currentUserOrganization = getCurrentOrg();

  const {
    params: { organizationId, profileId, tab },
  } = match;

  const panes = props => [
    {
      menuItem: 'Overview',
      render: () => <Overview {...props} />,
    },
    {
      menuItem: 'Saving Groups',
      render: () => <ProfileGroups {...props} />,
    },
    {
      menuItem: 'Requests',
      render: () =>
        pathname === `/profiles/${profileId}/requests` ? (
          <Requests {...props} />
        ) : (
          <SingleLoanRequest {...props} />
        ),
    },
    {
      menuItem: 'Settings',
      render: () => <EditProfile {...props} />,
    },
  ];

  const tabs = {
    overview: { index: 0, name: 'overview' },
    groups: { index: 1, name: 'groups' },
    requests: { index: 2, name: 'requests' },
    settings: { index: 3, name: 'settings' },
  };

  const activeIndex = !tabs[tab] ? 0 : tabs[tab].index;

  const getPath = tabName =>
    canAccess('profiles', null, {
      currentOrg: currentUserOrganization,
    })
      ? `/profiles/${profileId}/${tabName}`
      : `/organizations/${organizationId}/profiles/${profileId}/${tabName}`;

  const handleTabChange = (e, { activeIndex }) => {
    const key = Object.keys(tabs).find(
      key => tabs[key].index === activeIndex,
    );
    push(getPath(key));
  };

  return (
    <Tab
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes({ location, history, match })}
      onTabChange={handleTabChange}
    />
  );
};

ProfileTab.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

ProfileTab.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  match: PropTypes.objectOf(Object).isRequired,
};

export default ProfileTab;

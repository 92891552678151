/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Message, Button, Table } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Toolbar from 'components/common/Toolbar';
import Loader from 'components/common/PageLoader';
import getCurrentOrg from 'helpers/getCurrentOrg';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import './index.scss';
import formatNumber from 'helpers/formatNumber';
import capitalize from 'utils/capitalize';
import getSingleLoanRequest from 'redux/actions/loanRequests/getSingleLoanRequest';
import LoanAmendModal from 'containers/MicroLoanRequests/AmendModal';
import LoanApprovalModal from 'containers/MicroLoanRequests/ApprovalModal';
import currencyFormat from 'utils/currencyFormat';
import InternalLoanOverview from './InternalLoanOverview';

const SingleLoanRequest = () => {
  const params = useParams();
  const {
    organizationId,
    profileId: theProfileId,
    requestId,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const [open, setOpen] = useState(null);

  const currentUserOrganization = getCurrentOrg();

  const { loading, error, data } = useSelector(
    ({ loanRequests: { singleLoanRequest } }) => singleLoanRequest,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getSingleLoanRequest(requestId)(dispatch);
  }, [requestId]);

  useEffect(() => {
    getProfileOverview(
      organizationId || currentUserOrganization?.id,
      profileId,
    )(dispatch);
  }, [organizationId, currentUserOrganization, profileId]);

  return (
    <div className="single-loan-request">
      <LoanAmendModal
        open={open === 'amend'}
        setOpen={setOpen}
        request={data}
      />
      <LoanApprovalModal
        open={open === 'approve'}
        setOpen={setOpen}
        request={data}
      />
      <InternalLoanOverview
        open={open === 'overview-info'}
        setOpen={setOpen}
        request={data}
      />
      <Toolbar
        header={{
          title:
            data?.group?.name &&
            `Micro loan History: ${data?.group?.name}`,
          navigation: true,
          backUrl: `/profiles/${profileId}/requests`,
          rightComponent: (
            <>
              {data.status === 'approved' || (
                <>
                  <Button
                    basic
                    color="green"
                    content="Approve"
                    onClick={() => {
                      setOpen('approve');
                    }}
                  />
                  <Button
                    basic
                    color="blue"
                    content="Amend"
                    onClick={() => {
                      setOpen('amend');
                    }}
                  />
                </>
              )}
              <Button
                primary
                content="Internal loans overview"
                onClick={() => {
                  setOpen('overview-info');
                }}
              />
            </>
          ),
        }}
      />

      <div className="single-loan-request__content scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error}</Message>
        ) : (
          <>
            <div className="single-loan-request__content__top">
              <div className="single-loan-request__content__top__item">
                Loan amount : {currencyFormat(data.amount)}
              </div>
              <div className="single-loan-request__content__top__item">
                Repayment Days: {data.repayment_days}
              </div>
              <div className="single-loan-request__content__top__item">
                Installments: {data.repayment_installments}
              </div>
              <div className="single-loan-request__content__top__item">
                Payment/ instalment:{' '}
                {currencyFormat(data.repayment_installment_amount)}
              </div>
              <div className="single-loan-request__content__top__item">
                Interest Loan: {currencyFormat(data.loans_interest)}
              </div>
              <div className="single-loan-request__content__top__item">
                Loan reason : {data.purpose}
              </div>
              <div className="single-loan-request__content__top__item">
                Paid amount : {currencyFormat(data.loan_paid_amount)}
              </div>
              <div className="single-loan-request__content__top__item">
                Due balance : {currencyFormat(data.loans_balance)}
              </div>
            </div>
            <div className="single-loan-request__content__table">
              <Table basic="very" size="small" textAlign="left">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Activity Type</Table.HeaderCell>
                    <Table.HeaderCell>
                      Paid amount (RWF)
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Due amount (RWF)
                    </Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body
                  className={`single-loan-request__content__table__tbody wallet__transactions--table-body ${true &&
                    'lg-tbody'}`}
                >
                  {data.installments?.map(installment => {
                    return (
                      <Table.Row
                        key={installment.id}
                        className="table-row cursor"
                      >
                        <Table.Cell>Loan Repayment</Table.Cell>
                        <Table.Cell>
                          {formatNumber(
                            installment.loans_repayment_amount,
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {formatNumber(installment.loans_balance)}
                        </Table.Cell>
                        <Table.Cell className="flex flex-center-vertical">
                          <div
                            className={
                              installment.status === 'late'
                                ? 'red-dot'
                                : 'green-dot'
                            }
                          />
                          {capitalize(installment.status)}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(installment.repayment_date).format(
                            'MMM DD, YYYY',
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleLoanRequest;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import LineChart from 'components/common/charts/LineChart/Container';
import {
  greenColor,
  blueColor,
  orangeColor,
  redColor,
} from 'utils/chartColors';
import { cleanCumulative } from 'helpers/toCumulativeDates';
import todaysDate from 'utils/todaysDate';
import formatNumber from 'helpers/formatNumber';
import currencyFormat from 'utils/currencyFormat';

const LineCharts = ({ data, year }) => {
  const {
    groups_count: groupsCount,
    groups = [],
    cumulative_profile_loans = [],
    cumulative_profile_loans_total = 0,
    profile = {},
    current_savings = [],
    current_savings_total = 0,
    current_loans = [],
    current_loans_total = 0,
  } = data;

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Saving Groups',
              viewMoreRedirect: false,
              viewKey1: 'groups',
              view: 'general',
              date: todaysDate(year),
              amount: formatNumber(groupsCount),
              data: groups,
              background: greenColor,
              dataKey: 'uv',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: `Current Savings`,
              viewMoreRedirect: false,
              viewKey1: 'current-savings',
              viewKey: 'loans',
              view: 'general',
              date: todaysDate(year),
              amount: `${currencyFormat(current_savings_total)}`,
              data: cleanCumulative(current_savings),
              background: blueColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column width="8">
          <LineChart
            {...{
              title: `${profile?.name} Loans`,
              viewMoreRedirect: false,
              viewKey1: 'cumulative-loans',
              viewKey: 'loans',
              view: 'general',
              date: todaysDate(year),
              amount: `${currencyFormat(
                cumulative_profile_loans_total,
              )}`,
              data: cleanCumulative(cumulative_profile_loans),
              background: orangeColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
        <Grid.Column width="8">
          <LineChart
            {...{
              title: 'Outstanding Loans',
              viewMoreRedirect: false,
              viewKey1: 'outstanding-loans',
              view: 'general',
              date: todaysDate(year),
              amount: `${currencyFormat(current_loans_total)}`,
              data: current_loans,
              background: redColor,
              dataKey: 'count',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LineCharts.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  year: PropTypes.string.isRequired,
};

export default LineCharts;

export default [
  {
    text: 'access_bank',
    value: 'true',
  },
  {
    text: 'telco',
    value: 'false',
  },
];

import CreateProfile from 'containers/Profiles/CreateProfile';

export default {
  exact: true,
  name: 'Create Profile',
  protected: true,
  path: '/profiles/new',
  component: CreateProfile,
  permissions: ['all'],
};

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ListProfiles from 'containers/Profiles/AllProfiles/ListProfiles';

const AllProfiles = ({ history, openSingleProfile }) => {
  const { items, meta, loading } = useSelector(
    ({ profiles: { profileList } }) => profileList,
  );

  const goToSingleProfile = profileId => {
    history.push(`/profiles/${profileId}`);
  };

  return (
    <ListProfiles
      history={history}
      items={items}
      loading={loading}
      meta={meta}
      openSingleProfile={openSingleProfile || goToSingleProfile}
      all
    />
  );
};

AllProfiles.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  openSingleProfile: PropTypes.func,
};

AllProfiles.defaultProps = {
  openSingleProfile: null,
};

export default AllProfiles;

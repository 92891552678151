import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react';
import Thumbnail from 'components/common/Thumbnail';
import currencyFormat from 'utils/currencyFormat';
import './details.scss';

const ProfileDetails = ({ data }) => {
  const { profile } = data;
  return (
    <div className="group-info">
      <div className="info-wrapper">
        <div className="header">
          <Thumbnail
            name={profile?.name}
            style={{ width: '50px', height: '50px' }}
          />
          <div className="name">{profile?.name}</div>
        </div>

        <div className="group-details">
          <div className="details">
            <div className="detail">
              <div>
                <h3>Monthly Savings</h3>
                <p>
                  {`${currencyFormat(profile?.min_monthly_savings)}`}{' '}
                  -{' '}
                  {`${currencyFormat(profile?.max_monthly_savings)}`}
                </p>
              </div>
              <div className="align-right">
                <h3>Monthly Loans</h3>
                <p>
                  {`${currencyFormat(profile?.min_monthly_loans)}`} -{' '}
                  {`${currencyFormat(profile?.max_monthly_loans)}`}
                </p>
              </div>
            </div>

            <div className="detail">
              <div>
                <h3>Age range</h3>
                <p>-</p>
              </div>
              <div className="align-right">
                <h3>Cycles count</h3>
                <p>{`${profile?.cycles_count}`}</p>
              </div>
            </div>

            <div className="detail">
              <div>
                <h3>Group Types</h3>
                <p>
                  {`${profile?.groups_types
                    .map(type => type)
                    .join(', ')}`}
                </p>
              </div>
            </div>

            <div className="detail">
              <div>
                <h3>Areas of interest</h3>
                {!!profile?.provinces?.length && (
                  <p>
                    <b>Provinces:</b>{' '}
                    <span>{profile?.provinces?.length || 0}</span>{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content={
                        <>
                          <h4>Provinces</h4>
                          <span className="status-popup">
                            {`${[...(profile?.provinces || [])]
                              .map(area => area)
                              .join(', ')}`}
                          </span>
                        </>
                      }
                      on="hover"
                      hoverable
                      basic
                    />
                  </p>
                )}
                {!!profile?.districts?.length && (
                  <p>
                    <b>Districts:</b>{' '}
                    <span>{profile?.districts?.length || 0}</span>{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content={
                        <>
                          <h4>Districts</h4>
                          <span className="status-popup">
                            {`${[...(profile?.districts || [])]
                              .map(area => area)
                              .join(', ')}`}
                          </span>
                        </>
                      }
                      on="hover"
                      hoverable
                      basic
                    />
                  </p>
                )}
                {!!profile?.sectors?.length && (
                  <p>
                    <b>Sectors:</b>{' '}
                    <span>{profile?.sectors?.length || 0}</span>{' '}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content={
                        <>
                          <h4>Sectors</h4>
                          <span className="status-popup">
                            {`${[...(profile?.sectors || [])]
                              .map(area => area)
                              .join(', ')}`}
                          </span>
                        </>
                      }
                      on="hover"
                      hoverable
                      basic
                    />
                  </p>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="bottom group-details">
          <div />
          <div className="align-right">
            <p>
              Created on{' '}
              {`${moment(profile?.created_at).format(
                'MMM DD, YYYY',
              )}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
ProfileDetails.defaultProps = {
  data: {},
};

ProfileDetails.propTypes = {
  data: PropTypes.objectOf(Object),
};

export default ProfileDetails;

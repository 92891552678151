import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';
import { Provinces, District, Sector } from 'rwanda';
import './index.scss';
import {
  toDropDownOptions,
  toAreaDropDownOptions,
} from 'utils/dropdownOptions';
import MySelect from '../CustomSelect';

const InterventionArea = ({
  name,
  data,
  onChange,
  onIconChange,
  hasVillage,
  onFieldChange,
  goBack,
  isCaseSensitive,
}) => {
  const [form, setForm] = useState({});

  const getDistricts = () => {
    const { provinces = [] } = form;
    if (provinces.length === 0) {
      return [];
    }
    const districts = [];
    for (let i = 0; i <= provinces.length; i += 1) {
      if (provinces[i]) {
        District(provinces[i]).forEach(item => {
          districts.push({ value: item, province: provinces[i] });
        });
      }
    }
    return districts;
  };
  const getSectors = () => {
    const { provinces = [], districts = [] } = form;
    if (provinces.length === 0 || districts.length === 0) {
      return [];
    }
    const sectors = [];
    for (let i = 0; i <= provinces.length; i += 1) {
      for (let j = 0; j <= districts.length; j += 1) {
        if (provinces[i] && districts[i]) {
          // eslint-disable-next-line no-unused-expressions
          Sector(provinces[i], districts[j]) &&
            Sector(provinces[i], districts[j]).forEach(item => {
              sectors.push({ value: item, province: provinces[i] });
            });
        }
      }
    }
    return sectors;
  };

  useEffect(() => {
    onChange(null, {
      name: 'intervention_areas',
      value: form,
      sectors: getSectors(),
      districts: getDistricts(),
    });
  }, [form]);

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
    onFieldChange({ name, value });
  };

  return (
    <div className="areas-of-intervention">
      <div className="areas-of-intervention__title">
        {goBack && (
          <div className="areas-of-intervention__button">
            <button
              type="button"
              className="back-button"
              onClick={onIconChange}
            >
              <Icon size="large" name="arrow left" />
            </button>
          </div>
        )}
        <div className="areas-of-intervention__label">{name}</div>
      </div>
      <Form.Group widths="equal">
        <Form.Field
          name="provinces"
          value={form.provinces}
          control={MySelect}
          fluid
          options={toDropDownOptions(
            Provinces(),
            isCaseSensitive || true,
          )}
          label={{
            children: 'Provinces',
            htmlFor: 'form-input-province',
          }}
          placeholder="Provinces"
          defaultValue={data}
          onChange={handleChange}
        />
        <Form.Dropdown
          fluid
          Required
          control={MySelect}
          label="Districts"
          selection
          value={form.districts}
          placeholder={data.district || 'District'}
          options={toAreaDropDownOptions(
            getDistricts(),
            isCaseSensitive,
          )}
          name="districts"
          onChange={handleChange}
          selectOnBlur={false}
        />
      </Form.Group>
      {hasVillage && (
        <>
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              Required
              search
              label="Sectors"
              selection
              value={form.sectors}
              control={MySelect}
              placeholder={data.sector || 'Sector'}
              options={toAreaDropDownOptions(
                getSectors(),
                isCaseSensitive,
              )}
              name="sectors"
              onChange={handleChange}
              selectOnBlur={false}
            />
            <Form.Dropdown
              fluidRequired
              label="Cells"
              disabled
              selection
              placeholder="ALL"
            />
          </Form.Group>
          <Form.Dropdown
            fluidRequired
            disabled
            label="Villages"
            selection
            placeholder="ALL"
          />
        </>
      )}
      {!hasVillage && (
        <Form.Dropdown
          fluid
          Required
          search
          label="Sectors"
          selection
          value={form.sectors}
          control={MySelect}
          placeholder={data.sector || 'Sector'}
          options={toAreaDropDownOptions(
            getSectors(),
            isCaseSensitive,
          )}
          name="sectors"
          onChange={handleChange}
          selectOnBlur={false}
        />
      )}
    </div>
  );
};

InterventionArea.defaultProps = {
  name: 'Intervention Area',
  goBack: false,
  hasVillage: true,
  isCaseSensitive: false,
  onFieldChange: () => null,
  onIconChange: () => null,
  onChange: () => null,
};

InterventionArea.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  name: PropTypes.string,
  onIconChange: PropTypes.func,
  hasVillage: PropTypes.string,
  goBack: PropTypes.bool,
  onFieldChange: PropTypes.func,
  isCaseSensitive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InterventionArea;

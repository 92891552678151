export default {
  allFeatures: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  deactivateFeature: {
    loading: false,
    message: null,
    error: null,
  },
  deactivateFeaturesBulk: {
    loading: false,
    message: null,
    error: null,
  },
  addTestFeature: {
    loading: false,
    message: null,
    error: null,
  },
  categoryFeatures: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  addFeature: {
    loading: false,
    data: [],
    error: null,
  },
  updateFeature: {
    loading: false,
    data: [],
    error: null,
  },
  singleFeature: {
    loading: false,
    error: null,
    data: [],
  },
  allBills: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  singleBill: {
    loading: false,
    error: null,
    data: null,
  },
  addBill: {
    loading: false,
    data: null,
    error: null,
  },
  updateBill: {
    loading: false,
    data: null,
    error: null,
  },
};

import {
  isFinanceRwandaAdmin,
  isOrganizationAdmin,
  isSA,
  isSSA,
  isSupportAdmin,
  isTechnicalLead,
  isRwandaAdmin,
  isCommunicationsAdmin,
} from 'helpers/checkRoles';
import permissions from '../../constants/permissions/index';

export const canAccess = (
  context,
  roles,
  {
    currentOrgId,
    currentOrg,
    orgUsers,
    additionalCondition = false,
  } = {},
) => {
  switch (context) {
    case permissions.canAccess.allUsers:
      return (
        roles && (isSSA(roles) || isSA(roles)) && additionalCondition
      );

    case permissions.canAccess.communications:
      return (
        roles &&
        (isCommunicationsAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canAccess.wallet:
      return (
        roles &&
        additionalCondition &&
        (isSSA(roles) ||
          isFinanceRwandaAdmin(roles) ||
          isSupportAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }) ||
          isOrganizationAdmin({ currentOrgId, orgUsers }))
      );

    case permissions.canAccess.featureSettings:
      return roles && isTechnicalLead(roles) && additionalCondition;
    case permissions.canAccess.profiles:
      return currentOrg?.type === 'Business ORG';
    case permissions.canAccess.singleUser:
      return roles && isSA(roles);
    default:
      return false;
  }
};

export const canPerformAction = (
  context,
  roles,
  { currentOrgId, orgUsers, additionalCondition = false } = {},
) => {
  switch (context) {
    case permissions.canPerformAction.wallet:
      return (
        roles &&
        (isSSA(roles) ||
          isFinanceRwandaAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }) ||
          (isOrganizationAdmin({ currentOrgId, orgUsers }) &&
            additionalCondition))
      );
    case permissions.canPerformAction.updateUser:
      return (
        roles &&
        (isSSA(roles) ||
          isSupportAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canPerformAction.editAdminUserRoles:
      return (
        roles &&
        (isSSA(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );
    case permissions.canPerformAction.assignInfluencerAlias:
      return (
        roles &&
        (isFinanceRwandaAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canPerformAction.editUserRoles:
      return (
        roles &&
        (isSSA(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }) ||
          isSupportAdmin(roles))
      );

    case permissions.canPerformAction.canAddEditFeature:
      return roles && isTechnicalLead(roles);

    case permissions.canPerformAction.canDeactivateFeature:
      return (
        roles && (isTechnicalLead(roles) || isSupportAdmin(roles))
      );
    case permissions.canPerformAction.canUnblockFeature:
      return (
        roles &&
        (isTechnicalLead(roles) ||
          isSupportAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );
    case permissions.canPerformAction.communications:
      return (
        roles &&
        (isCommunicationsAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canPerformAction.canTransferGroups:
      return (
        roles &&
        (isSupportAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canPerformAction.canRemoveAgent:
      return (
        roles &&
        (isSupportAdmin(roles) ||
          isRwandaAdmin({ roles, currentOrgId, orgUsers }))
      );

    case permissions.canPerformAction.resetGroupShares:
      return (roles && isSupportAdmin(roles)) || isRwandaAdmin(roles);

    case permissions.canPerformAction.canExport:
      return additionalCondition
        ? isOrganizationAdmin({ currentOrgId, orgUsers }) ||
            isSupportAdmin(roles) ||
            isFinanceRwandaAdmin(roles) ||
            isTechnicalLead(roles)
        : true;

    case permissions.canPerformAction.canAddBill:
      return roles && isRwandaAdmin({ roles });

    case permissions.canPerformAction.canEditBill:
      return roles && isRwandaAdmin({ roles });

    default:
      return false;
  }
};

export default {
  canAccess: {
    allUsers: 'allUsers',
    wallet: 'wallet',
    featureSettings: 'featureSettings',
    singleUser: 'singleUser',
    communications: 'communications',
    profiles: 'profiles',
  },
  canPerformAction: {
    wallet: 'wallet',
    updateUser: 'updateUser',
    canDeactivateUserRoles: 'canDeactivateUserRoles',
    canAddEditFeature: 'canAddEditFeature',
    canDeactivateFeature: 'canDeactivateFeature',
    editUserRoles: 'editUserRoles',
    editAdminUserRoles: 'editAdminUserRoles',
    canUnblockFeature: 'canUnblockFeature',
    canTransferGroups: 'canTransferGroups',
    assignInfluencerAlias: 'assignInfluencerAlias',
    resetGroupShares: 'resetGroupShares',
    canExport: 'canExport',
    communications: 'communications',
    canRemoveAgent: 'canRemoveAgent',
    canAddBill: 'canAddBill',
    canEditBill: 'canEditBill',
  },
};

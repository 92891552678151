/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Table, Message, Breadcrumb } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';
import getProfileGroups from 'redux/actions/organizations/profiles/profileGroups';
import Loader from 'components/common/PageLoader';
import Thumbnail from 'components/common/Thumbnail';
import getCurrentOrg from 'helpers/getCurrentOrg';
import isParentOrganization from 'helpers/isParentOrganization';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Empty from 'components/common/Empty';
import { isSuperAdmin } from 'helpers/checkRoles';
import { canAccess } from 'helpers/permissions';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import formatNumber from 'helpers/formatNumber';

const ProfileGroups = ({ backToProfileOverview }) => {
  const params = useParams();

  const {
    organizationId,
    profileId: theProfileId,
    option,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const { roles = [] } = useSelector(
    ({ user: { currentUser: { details = {} } = {} } = {} }) =>
      details,
  );
  const [pathnameError, setPathnameError] = useState('');
  const agentId = isSuperAdmin(roles) ? tabOption : option;

  const currentUserOrganization = getCurrentOrg();

  const isCurrentOrgParent = isParentOrganization();

  const { push } = useHistory();
  const { search, pathname } = useLocation();

  const { items, loading, error, meta } = useSelector(
    ({ profiles: { profileGroups } }) => profileGroups,
  );

  const {
    data: { profile },
  } = useSelector(
    ({ profiles: { profileOverview } }) => profileOverview,
  );

  const dispatch = useDispatch();
  const getGroups = (
    organizationId,
    profileId,
    queries = queryString.parse(search),
  ) => {
    return getProfileGroups(
      organizationId,
      profileId,
      queries,
    )(dispatch);
  };

  useEffect(() => {
    if (
      pathname === `/profiles/${profileId}/groups` &&
      !canAccess('profiles', null, {
        currentOrg: currentUserOrganization,
      })
    ) {
      setPathnameError('You are not authorized to view this page');
    } else if (
      pathname ===
        `organizations/${organizationId}/profiles/${profileId}/groups` &&
      !isCurrentOrgParent
    ) {
      setPathnameError('You are not authorized to view this page');
    }
  }, [currentUserOrganization, isCurrentOrgParent]);

  useEffect(() => {
    getGroups(
      organizationId || currentUserOrganization?.id,
      profileId,
    );
  }, [dispatch, organizationId, agentId]);

  useEffect(() => {
    getProfileOverview(
      organizationId || currentUserOrganization?.id,
      profileId,
    )(dispatch);
  }, [organizationId, currentUserOrganization, profileId]);

  const onSearch = queries => {
    getGroups(
      organizationId || currentUserOrganization?.id,
      profileId,
      queries,
    );
  };

  const onPaginate = queries => {
    getGroups(
      organizationId || currentUserOrganization?.id,
      profileId,
      queries,
    );
  };

  return (
    <div className="profile-group__lists">
      <Toolbar
        header={{
          title:
            !pathname.includes('/organizations/') && 'Saving Groups',
          navigation: false,
          leftComponent: pathname.includes('/organizations/') &&
            profile && (
              <Breadcrumb size="massive" className="customized-title">
                <Breadcrumb.Section active>
                  Profile
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section
                  className="cursor"
                  link={false}
                  onClick={() => {
                    backToProfileOverview(profile?.id);
                  }}
                  active
                >
                  {profile?.name}
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>Groups</Breadcrumb.Section>
              </Breadcrumb>
            ),
          rightComponent: (
            <ToolbarFooter
              onSearch={onSearch}
              onPaginate={onPaginate}
              paginationMeta={meta}
              exportCSVData={items}
              exportCSVFileName="groups"
            />
          ),
        }}
      />
      <div className="groups-table">
        {loading ? (
          <Loader />
        ) : error || pathnameError ? (
          <Message negative>{error.message || pathnameError}</Message>
        ) : items.length === 0 ? (
          <Empty message="No groups found" />
        ) : (
          <Table basic="very" size="large">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <span>Saving Groups</span>
                </Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                <Table.HeaderCell>
                  Cumulative Savings (RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Current Savings(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Outstanding Loans(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Wallet Balance(RWF)
                </Table.HeaderCell>
                <Table.HeaderCell className="sg-status">
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {items.map(
                ({
                  id,
                  name,
                  members_count: members,
                  savings_balance: currentSavings,
                  savings_total: cumulativeSavings,
                  loans_balance: outstandingLoan,
                  wallet_balance: walletBalance,
                  status,
                }) => (
                  <Table.Row
                    key={id}
                    onKeyDown={null}
                    role="button"
                    tabIndex="-1"
                    onClick={() =>
                      push(
                        `/organizations/${organizationId}/groups/${id}`,
                      )
                    }
                  >
                    <Table.Cell>
                      <div
                        className={`group-name ${status ===
                          'inactive' && 'disabled'}`}
                      >
                        <Thumbnail avatar={null} name={name} />
                        <span>{name}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{members}</Table.Cell>
                    <Table.Cell>
                      {formatNumber(cumulativeSavings)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(currentSavings)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(outstandingLoan)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatNumber(walletBalance)}
                    </Table.Cell>
                    <Table.Cell className="status-field sg-status">
                      {status === 'active' ? (
                        <span className="group-status active">
                          Active
                        </span>
                      ) : (
                        <span className="group-status inactive">
                          Inactive
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

ProfileGroups.propTypes = {
  backToProfileOverview: PropTypes.func.isRequired,
};

export default ProfileGroups;
